$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);
@import '@/styles/_globals';

.Carousel {
  position: inherit;

  @include isDesktop {
    overflow: hidden;
  }

  .IoGameTile {
    &:not(.--finished) {
      opacity: 0;
    }
  }

  &__Button {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    padding: 0 0.5rem;
    width: $gameTileWidth * 0.35;
    box-sizing: content-box;

    &::after {
      @include isTabletOrGreater {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }

    .Button {
      padding: 1.65em;
      z-index: 2;

      .Icon {
        width: 1em;
        height: 1em;
      }
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
